import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import Logo from "./assets/logo.png";
import analyticsProvider from "./analyticsProvider";
import "./App.css";

function App() {
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const [uId, setUId] = useState("");

  useEffect(() => {
    analyticsProvider.init();

    const userId = params.get("userId");
    const token = params.get("token");
    const type = params.get("type");
    const transactionId = params.get("transactionId");

    // Track analytics
    analyticsProvider.idUser(userId);
    analyticsProvider.trackEvent("OpenWidget", {
      widgetType: type,
      transactionId,
      userId,
      token,
    });

    const widgetParams = {
      elementId: "widget-root",
      senderId: userId,
      width: "100%",
      height: "200px",
      type: type,
      locale: "en",
      multiStep: true,
      stylesheet: "https://payments.onepurse.co/widget.css",
      token: token,
    };

    if (transactionId?.length > 0) {
      widgetParams.transactionId = transactionId;
    }

    const widget = new window.MachnetWidget(widgetParams);

    widget.init();

    setUId(userId);
  }, [params]);

  useEffect(() => {
    window.addEventListener("message", function (e) {
      switch (e.data.status) {
        case "CARD_ADDED":
          analyticsProvider.trackEvent("CardAdded", { userId: uId });
          window.location = "https://onepurse.co";
          break;
        case "CARD_ERROR":
          analyticsProvider.trackEvent("CardError", { userId: uId });
          toast.error("Error adding card!");
          break;
        case "VERIFIED":
          analyticsProvider.trackEvent("KYC_Verified", { userId: uId });
          toast.success("Successfully verified KYC!");
          window.location = "https://onepurse.co";
          break;
        case "PENDING":
          analyticsProvider.trackEvent("KYC_Pending", { userId: uId });
          toast.success("Pending KYC verification!");
          break;
        case "THREE_DS_VERIFIED":
          analyticsProvider.trackEvent("THREE_DS_VERIFIED", { userId: uId });
          toast.success("Transaction verified and is now processing!");
          window.location = "https://onepurse.co";
          break;
        case "THREE_DS_HOLD":
          analyticsProvider.trackEvent("THREE_DS_HOLD", { userId: uId });
          toast.success("Something went wrong! Please contact support.");
          break;
        case "THREE_DS_FAILED":
          analyticsProvider.trackEvent("THREE_DS_FAILED", { userId: uId });
          toast.success("Verification Failed! Transaction will be cancelled.");
          break;
        case "THREE_DS_IN_PROGRESS":
          analyticsProvider.trackEvent("THREE_DS_IN_PROGRESS", { userId: uId });
          toast.success("Verification in progress.");
          break;
        default:
          return 0;
      }
    });
  }, [params, uId]);

  return (
    <div className="App">
      <div className="py-5 px-4 shadow bg-gray-800 mb-10">
        <div className="max-w-6xl mx-auto">
          <a href="https://onepurse.co">
            <img src={Logo} alt="Onepurse Logo" width="150px" />
          </a>
        </div>
      </div>
      <div id="widget-root" className="h-screen"></div>
    </div>
  );
}

export default App;
