import {
  init,
  track,
  Identify,
  identify,
  reset,
  setUserId,
} from "@amplitude/analytics-browser";

class AnalyticsProvider {
  init() {
    init("e95d3899740e47a5c724b40bcdd8b385", {
      defaultTracking: true,
    });
  }

  idUser(uId) {
    setUserId(uId);
  }

  setUserParams(params) {
    const user = new Identify();

    for (const param in params) {
      user.set(param, params[param]);
    }

    identify(user);
  }

  trackEvent(eventName, params = null) {
    track(`Widget: ${eventName}`, params);
  }

  async resetAnalytics() {
    await track("Logout").promise();
    reset();
  }
}

const instance = new AnalyticsProvider();

export default instance;
